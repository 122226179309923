import React, { useEffect, useState } from 'react';
import { getAuth, signOut, updatePassword } from 'firebase/auth';
import './ExamsManagement.scss';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { TableWidget } from '../../components/table-widget/TableWidget';
import { useNavigate } from 'react-router-dom';
import { useUserState } from '../../services/User';
import { collection, doc, orderBy, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import { useStaticState } from '../../services/StaticContext';
import { Exam } from '../../models/exam';
import { ExamType } from '../../models/exam-type';
import { Button, Form, Modal } from 'react-bootstrap';
import { ArrayUtils } from '../../util/array-utils';
import { Role } from '../../models/role.enum';
import { useLoader } from '../../services/LoaderProvider';

const ExamsManagement = () => {
  const user = useUserState();
  const navigate = useNavigate();
  const { startLoader, stopLoader } = useLoader();
  const { examTypes, subjects } = useStaticState();
  const [examList, setExamList] = useState<Exam[]>();

  const [showBulkChanges, setShowBulkChanges] = useState(false);
  const [selectedExams, setSelectedExams] = useState<Exam[]>([]);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [bulkOnline, setBulkOnline] = useState<boolean>(true);
  const [bulkDate, setBulkDate] = useState<Date | null>(null);

  useEffect(() => {
    startLoader();
    if (user?.id && examTypes && subjects) {
      getApi();
    }
  }, [user?.id, examTypes, subjects]);

  function handleBulkChanges() {
    setShowBulkChanges(!showBulkChanges);
  }

  function handleBulkConfirm() {
    console.log(selectedExams)
    if (!selectedExams.length) {
      return;
    }
    setShowBulkModal(true);
  }

  function handleBulkSubmit() {
    const batch = writeBatch(db);

    selectedExams.forEach((exam) => {
      const examCopy = { ...exam } as Exam;
      examCopy.isOnline = bulkOnline;
     // if (bulkOnline && !examCopy.onlineOnce) {
        examCopy.expectedDate = bulkDate ? bulkDate.toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10);
     // }
      if (bulkOnline) {
        examCopy.onlineOnce = true;
      }
      const examRef = doc(db, 'exam', examCopy.id);
      batch.set(examRef, examCopy);
    });

    batch.commit().then(() => {
      setShowBulkModal(false);
      setShowBulkChanges(false);
      setSelectedExams([]);
      getApi();
    }).catch((error: any) => {
      console.error('Error updating exams: ', error);
    });
  }

  async function getApi() {
    const customQuery = query(collection(db, 'exam'), where('deleted', '==', false), orderBy('isPaid', 'asc'), orderBy('difficulty', 'asc'), orderBy('order', 'asc'));
    const exams = await QueryUtils.executeQuery(customQuery);

    exams.forEach((exam: any) => {
      if (!exam.order) {
        console.log(exam)
      }
    })
    setExamList(exams as any);
    stopLoader();
  }

  function createExam() {
    navigate('/exam-creation');
  }

  async function changeExamStatus(exam: Exam, online: boolean) {
    const examCopy = {...exam} as Exam;
    examCopy.isOnline = online;
    if (online && !examCopy.onlineOnce) {
      examCopy.published_date = new Date();
    }
    if (online) {
      examCopy.onlineOnce = true;
    }
    setExamList(ArrayUtils.updateObjectInArray(examList, examCopy));

    await setDoc(doc(db, 'exam', examCopy.id), examCopy);
  }

  return (
    <ProtectedPages>
    {(user.id && user.role == Role.ADMIN) && <section id="exams-management">
      { !showBulkChanges && <div className='add-new-container'>
        <button className='btn btn-primary' onClick={handleBulkChanges}>
          Bulk Changes
        </button>
         <button className='btn btn-primary' onClick={createExam}>Add new</button>
      </div>}

      {showBulkChanges && <div className='bulkchanges-container'>
         <button className='btn btn-secondary' onClick={handleBulkChanges}>Cancel</button>
        <button className='btn btn-primary' disabled={!selectedExams?.length} onClick={handleBulkConfirm}>
          Confirm
        </button>
      </div>}

      {examTypes?.map((type: ExamType, i: any) => (
        <TableWidget
          key={type.id}
          onStatusChange={changeExamStatus}
          exams={examList?.filter((e) => e.type === type.id)}
          isAdminPage={true}
          examType={type}
          showBulkChanges={showBulkChanges}
          selectedExams={selectedExams}
          setSelectedExams={setSelectedExams}
        />
      ))}

      <Modal show={showBulkModal} onHide={() => setShowBulkModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="bulkOnline" className='d-flex flex-column gap-2'>
              <Form.Label>Online Status</Form.Label>
              <Form.Check
                type="radio"
                label="Online"
                name="bulkOnline"
                checked={bulkOnline}
                onChange={() => setBulkOnline(true)}
              />
              <Form.Check
                type="radio"
                label="Offline"
                name="bulkOnline"
                checked={!bulkOnline}
                onChange={() => setBulkOnline(false)}
              />
            </Form.Group>
            <Form.Group controlId="bulkDate" className='mt-5'>
              <Form.Label>Expected Date</Form.Label>
              <Form.Control
                type="date"
                value={bulkDate ? bulkDate.toISOString().substring(0, 10) : ''}
                onChange={(e) => setBulkDate(e.target.value ? new Date(e.target.value) : null)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBulkModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleBulkSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </section>}
  </ProtectedPages>
  );
};
export default ExamsManagement;