import { collection, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../components/footer-landing-page/Footer';
import { TableWidget } from '../../components/table-widget/TableWidget';
import { Exam } from '../../models/exam';
import { ExamType } from '../../models/exam-type';
import { db } from '../../services/firebase';
import { useStaticState } from '../../services/StaticContext';
import { QueryUtils } from '../../util/query-utils';
import './FreeTest.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FreeTest = () => {
  const navigate = useNavigate();
  //const { startLoader, stopLoader } = useLoader();
  const { examTypes } = useStaticState();
  console.log(examTypes, 'examTypes')
  const [examList, setExamList] = useState<Exam[]>();
  const [selectedType, setSelectedType] = useState<any>('Wa1XNOCHd6OqZ29M9K4D');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getExamsFromDb();
  }, []);

  async function getExamsFromDb() {
    const customQuery = query(collection(db, 'exam'), where('deleted', '==', false), orderBy('isPaid', 'asc'), orderBy('difficulty', 'asc'), orderBy('order', 'asc'));
    const exams = await QueryUtils.executeQuery(customQuery);
    const filteredExams = exams.filter((e: Exam) => {
      return e.isOnline || e.expectedDate;
    }) as Exam[]
    setExamList(filteredExams);
    setLoading(false);

    localStorage.setItem('totalExamsCount', filteredExams?.reduce((count, exam) => count + (exam.questions?.length ?? 0), 0).toString() || '10000');
  }

  return (
    <section id="free-test">
      <div className="free-test-content">
        <div className="first-section">
          <div className="first-section-container">
            <div className="left-panel-first-section">
              <span className="title-first-section">
                More than {localStorage.getItem('totalExamsCount') || '10000'} <br /> questions available
                <br /> on our platform.
              </span>

              <div className="left-panel-boxes">
                <div className="line-1">
                  <img src="media/images/star.png" alt="star.png" />
                  <span>10,000+ Topicwise Questions & Answers Included</span>
                </div>
                <div className="line-2">
                  <img src="media/images/star.png" alt="star.png" />
                  <span>Best for 11+ Grammar & Independent School Exams</span>
                </div>
                <div className="line-3">
                  <img src="media/images/star.png" alt="star.png" />
                  <span>Improve Speed, Accuracy & Time Management</span>
                </div>
                <div className="line-4">
                  <img src="media/images/star.png" alt="star.png" />
                  <span>Instantly view answers on ly on website</span>
                </div>
              </div>
            </div>

            <div>
              <img className="image-first-section" src="media/images/img-student-online.png" alt="img-online-test.png" />
            </div>
          </div>
        </div>
        <div className="second-section">
          <span className="second-section-test">Access FREE 11+ mock exams:</span>
        </div>
        <div className="third-section box-shadow">
          <div className="free-test-container-header">
            {examTypes?.filter((type: any) => type.id !== '7yL8rJSN8Drr01fBtHDa')?.map((type: ExamType, i: any) => (
              <div key={i} className={'type-exams' + (selectedType === type.id ? ' selected' : '')} onClick={() => setSelectedType(type.id)}>
                {type.id === 'Wa1XNOCHd6OqZ29M9K4D' && <img className="exam-icons" src="/icons/gla-logo.png" />}
                {type.id === 'cDaUveEJwqt084KzaCkI' && (
                  <div className="d-flex">
                    <img className="exam-icons" src="/icons/tiffin-girls.jpg" />
                    <img className="exam-icons" src="/icons/tiffin-boys.png" />
                  </div>
                )}
                {type.id === 'GiXgZHoJfIwEY1ID2vrn' && <img className="exam-icons" src="/icons/sutton-logo.png" />}
                <span className="exam-name-text">{type.name}</span>
              </div>
            ))}
          </div>

          <div className="fourth-section">
            <span className="fourth-section-text">Get instant access to all {localStorage.getItem('totalExamsCount') || '10000'} questions.</span>
            <button className="fourth-section-button">
              <span className="fourth-section-button-text" onClick={() => navigate('/signup')}>
                Sign Up Now!
              </span>
            </button>
          </div>

          <div className="free-test-container-body">
            {loading ? (
              <div className="skeleton-loader">
                <Skeleton height={68} count={10} />
              </div>
            ) : (
              <>
                {examTypes
                  ?.filter((type) => type.id === selectedType)
                  ?.map((type: ExamType, i: any) => (
                    <div key={type.id}>
                      <TableWidget isFreePage={true} exams={examList?.filter((e) => e.type == type.id)} examType={type} />
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default FreeTest;
