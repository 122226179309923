import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

type AddChildFormProps = {
  onCancel: () => void;
};

const AddChildForm: React.FC<AddChildFormProps> = ({ onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [birthDate, setBirthDate] = useState<any>();

  const initialValues = {
    firstName: '',
    lastName: '',
    birthDate: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('First name is required'),
    lastName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
    birthDate: Yup.string().required('Date of birth is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      // Handle form submission
      console.log('Child Info:', values);
      setLoading(false);
    },
  });

  function dateToString(date: any) {
    if (!date) return '';
    const isoDate = new Date(date).toISOString();
    return `${isoDate.substring(0, 10)}`;
  }

  function inputBuilder(fieldName: string, placeholder: string, disabled = false) {
    const touched: any = formik.touched;
    const touchedField: any = touched[fieldName];
    const errors: any = formik.errors;
    const errorsField = errors[fieldName];

    return (
      <>
        <input
          placeholder={placeholder}
          {...formik.getFieldProps(fieldName)}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': touchedField && errorsField },
            {
              'is-valid': touchedField && !errorsField,
            }
          )}
          disabled={disabled}
          type={fieldName}
          name={fieldName}
          autoComplete="off"
        />
        {touchedField && errorsField && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{errorsField}</div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="box-password">
      <form className="form w-100" onSubmit={formik.handleSubmit}>
        <div className="profile-data-container">
          <div>
            <label className="form-label fs-6 fw-bolder text-dark">Pupil's First Name</label>
            {inputBuilder('firstName', 'First Name')}
          </div>

          <div>
            <label className="form-label fs-6 fw-bolder text-dark">Pupil's Last Name</label>
            {inputBuilder('lastName', 'Last Name')}
          </div>

          <div>
            <label className="form-label fs-6 fw-bolder text-dark">Pupil's Date of Birth</label>
            <input
              type="date"
              className="form-control form-control-lg form-control-solid"
              value={dateToString(birthDate)}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </div>
        </div>
        <div className="update-profile-btn">
          <div className="update-btn">
            <button
              type="submit"
              id="kt_login_signin_form_submit_button"
              className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {!loading && <span className="indicator-label">Add Child</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary fw-bolder fs-6 px-8 py-4 my-3 me-3"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddChildForm;