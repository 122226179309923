import './TableWidget.scss';
import React, { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../assets/helpers';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ProgressBar, Spinner, Table } from 'react-bootstrap';
import { ProtectedPages } from '../../layouts/admin/ProtectedLayout';
import { Exam } from '../../models/exam';
import { ExamType } from '../../models/exam-type';
import { ChevronDoubleRight, PencilFill, StarFill } from 'react-bootstrap-icons';
import { addDoc, collection, doc, onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useUserState } from '../../services/User';
import { QueryUtils } from '../../util/query-utils';
import { ConfirmModal } from '../confirm-modal/ConfirmModal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { faClock, faComment } from '@fortawesome/free-regular-svg-icons';
import { LoaderProvider, useLoader } from '../../services/LoaderProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExamResultsModal } from '../exam-results-modal/ExamResultsModal';
import { Question } from '../../models/question';
import { ExamResult } from '../../models/exam-result';
import { useStaticState } from '../../services/StaticContext';
import Tooltip from 'react-bootstrap/Tooltip';
import { differenceInDays, subDays } from 'date-fns';
import { ModalPayment } from '../modal-payment/ModalPayment';
import { Utils } from '../../util/string-utils';

type Props = {
  exams: Exam[] | undefined;
  examType: ExamType;
  isAdminPage?: boolean;
  resultsLoaded?: boolean;
  showBulkChanges?: boolean;
  selectedExams?: Exam[];
  setSelectedExams?: any;
  isFreePage?: boolean;
  unlocked?: boolean;
  onStatusChange?: any;
};

const iconSize = '17px';

const TableWidget: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const confirmModalRef: any = useRef(null);
  const modalPaymentRef: any = useRef(null);
  const { examTypes, subjects, subSubjects } = useStaticState();

  const confirmOnlineRef: any = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  const confirmOfflineRef: any = useRef(null);
  const examResultModalRef: any = useRef(null);
  const goToExamEditRef: any = useRef(null);
  const user: any = useUserState();
  const [selectedExam, setSelectedExam] = useState<Exam>();
  const [examList, setExamList] = useState<Exam[]>();
  const [loadingCover, setLoadingCover] = useState<boolean>(true);
  const [hoveredRow, setHoveredRow] = useState<number>();
  const [copyIdText, setCopyIdText] = useState<string>('copy id');

  const [showZoomModal, setShowZoomModal] = useState(false);
  const handleCloseZoom = () => {
    setShowZoomModal(false);
    setTimeout(() => {
      //setLoadingCover(true)
    }, 1000);
  };

  const renderTooltip = (props: any, tooltip: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  useEffect(() => {
    if (props.exams) {
      setExamList(props.exams);
    }
  }, [props.exams]);

  function goToCreateExam(exam: Exam) {
    window.localStorage.setItem('currentNav', 'exam-creation');
    navigate(`/exam-creation/${exam.id}`);
  }

  function showConfirmDialog(exam: Exam) {
    if (exam.onGoing) {
      setSelectedExam(exam);
      goToPerformExam(exam);
    } else {
      setSelectedExam(exam);
      if (exam.coverImageUrl) {
        setLoadingCover(true);
        setShowZoomModal(true);
        setTimeout(() => {
          setLoadingCover(false);
        }, 2000);
      } else {
        confirmModalRef.current.handleShow();
      }
    }
  }

  function handleRowHover(index?: number) {
    setHoveredRow(index);
  }

  function goToPerformExam(exam?: Exam) {
    handleCloseZoom();
    window.localStorage.setItem('currentNav', 'exam-creation');
    if (exam) {
      createResult(exam);
    } else if (selectedExam) {
      createResult(selectedExam);
    }
  }

  async function createResult(exam: Exam) {
    startLoader();

    if (props.isAdminPage) {
      const resp = await addDoc(collection(db, 'preview-results'), { examId: exam.id, created_date: new Date() });
      stopLoader();
      navigate(`/exam-perform/${exam.id}/${resp.id}/preview-results`);
    } else if (props.isFreePage) {
      const resp = await addDoc(collection(db, 'public-results'), { examId: exam.id, created_date: new Date() });
      stopLoader();
      navigate(`/exam-perform/${exam.id}/${resp.id}/public-results/public`);
    } else {
      const querySub = query(
        collection(db, 'results'),
        where('userId', '==', user.id),
        where('examId', '==', exam.id),
        orderBy('created_date', 'desc')
      );
      const querySub2 = query(
        collection(db, 'additional-results'),
        where('userId', '==', user.id),
        where('examId', '==', exam.id),
        orderBy('created_date', 'desc')
      );
      const resp = await QueryUtils.executeQuery(querySub);
      const resp2 = await QueryUtils.executeQuery(querySub2);
      const userResults = resp2.concat(resp);
      let resultCollection = userResults?.length ? 'additional-results' : 'results';
      if (exam.onGoing && userResults.length === 1) resultCollection = 'results';

      window.localStorage.setItem('currentNav', 'exam-perform');
      const latestResult = userResults?.[0];
      if (latestResult && exam.onGoing) {
        stopLoader();
        navigate(`/exam-perform/${exam.id}/${userResults[0].id}/${resultCollection}`);
      } else {
        const resp = await addDoc(collection(db, resultCollection), { userId: user.id, examId: exam.id, created_date: new Date() });
        stopLoader();
        navigate(`/exam-perform/${exam.id}/${resp.id}/${resultCollection}`);
      }
    }
  }

  async function makeOnline() {
    const examCopy = { ...selectedExam } as Exam;
    props.onStatusChange(examCopy, true);
  }

  function showPaymentDialog(exam: any) {
    if (exam?.isPaid && !props.unlocked && user.role != 1) {
      modalPaymentRef.current.handleShow();
    }
  }

  async function makeOffline() {
    const examCopy = { ...selectedExam } as Exam;
    props.onStatusChange(examCopy, false);
  }

  function statusChange(exam: Exam) {
    setSelectedExam(exam);

    let isInvalid = false;
    exam.questions?.forEach((question) => {
      if (
        !question.questionImageUrl ||
        !question.options?.length ||
        !question.correctOptions?.length ||
        !question.subject ||
        !question.subSubject
      ) {
        isInvalid = true;
      }
    });

    if (exam?.isOnline) {
      confirmOfflineRef.current.handleShow();
      return;
    }
    if (isInvalid) {
      goToExamEditRef.current.handleShow();
    } else {
      confirmOnlineRef.current.handleShow();
    }
  }

  function goToExamEdit() {
    const examCopy = { ...selectedExam } as Exam;
    navigate(`/exam-creation-subject/${examCopy.id}`);
  }

  async function showResultModal(exam: Exam) {
    const querySub = query(
      collection(db, 'results'),
      where('userId', '==', user.id),
      where('examId', '==', exam.id),
      orderBy('created_date', 'desc')
    );
    const resp = await QueryUtils.executeQuery(querySub);

    window.localStorage.setItem('currentNav', 'exam-perform');
    const latestResult = resp?.[0];

    let graphData: any[] = [];
    exam.questions.forEach((question) => {
      const foundItem = graphData.find((item) => item.subSubject === question.subSubject);
      if (!foundItem) {
        graphData.push({
          subSubject: question.subSubject,
          correctAnswers: 0,
          total: exam.questions.filter((e) => e.subSubject === question.subSubject)?.length,
        });
      }

      if (answeredCorrectly(question, latestResult)) {
        const item = graphData.find((item) => item.subSubject === question.subSubject);
        item.correctAnswers++;
      }
    });

    graphData = graphData.map((item) => {
      return { ...item, subSubject: subSubjects.find((s) => s.id === item.subSubject)?.name };
    });
    //setSelectedExam(exam);
    examResultModalRef.current.handleShow({ examId: exam.id, graphData });
  }

  function answeredCorrectly(question: Question, result: ExamResult) {
    let isCorrect;
    const found = result?.answers?.find((answer) => answer.questionId === question.id);
    if (found && found.markedOptions?.length && found.markedOptions?.length === question.correctOptions?.length) {
      if (question.correctOptions?.length) {
        let anyWrong = true;
        question.correctOptions.forEach((correctOption) => {
          const right = found.markedOptions.some((m) => m === correctOption);
          if (!right) anyWrong = false;
        });

        if (anyWrong) isCorrect = true;
      }
    }

    return isCorrect;
  }

  function onSelectPlan(linkUrl: string) {
    modalPaymentRef.current.handleHide();
    props.isFreePage ? navigate('/signup/subscriptionIntent') : openPayModal(linkUrl);
  }

  function openPayModal(linkUrl: any) {
    startLoader();
    console.log('linkUrl', linkUrl);
    Utils.handleCheckoutSimple(linkUrl, user);
  }

  function onCopyClick(examId: string) {
    navigator.clipboard.writeText(examId);
    setCopyIdText('copied!');
    setTimeout(() => {
      setCopyIdText('copy id');
    }, 2000);
  }

  return (
    <div>
      <div id="exams-table" className="table-container">
        <div className="table-content">
          <Table>
            <thead>
              <tr>
                <th className="th-name">name</th>
                {!props.isAdminPage && <th> </th>}

                {props.isAdminPage && <th>questions</th>}
                {props.isAdminPage && <th>time</th>}

                {<th className="difficulty">difficulty</th>}
                {!props.isAdminPage && !props.isFreePage && (
                  <th className="title-score">
                    <span className="sc">score </span>
                    <span style={{ fontSize: '11px', textTransform: 'capitalize' }}>(1st Attempt)</span>
                  </th>
                )}
                <th className="action">Statistics</th>
              </tr>
            </thead>
            <tbody>
              {examList?.map((exam, index) => (
                <tr
                  onClick={() => setSelectedExam(exam)}
                  onMouseEnter={() => handleRowHover(index)}
                  onMouseLeave={() => handleRowHover(undefined)}
                  key={exam.id}
                >
                  <td className="exam-name-td">
                    <div className="name-first-row">
                      {props.showBulkChanges && (
                        <input
                          type="checkbox"
                          checked={props.selectedExams?.includes(exam)}
                          className='bulk-checkbox'
                          onChange={(e) => {
                            if (e.target.checked) {
                              props.setSelectedExams([...props.selectedExams!, exam]);
                            } else {
                              props.setSelectedExams(props.selectedExams!.filter((selectedExam) => selectedExam.id !== exam.id));
                            }
                          }}
                        />
                      )}
                      {exam.title}
                      {!props.isFreePage &&
                        !props.isAdminPage &&
                        exam.published_date &&
                        differenceInDays(new Date(), exam.published_date.toDate()) < 8 && <span className="text-new">New</span>}
                      {props.isAdminPage && (
                        <>
                          {!exam?.onlineOnce && <span className="text-new draft">Draft</span>}
                          <span className={'text-new' + (exam.isOnline ? ' online' : ' offline')} onClick={() => statusChange(exam)}>
                            {exam.isOnline ? 'Online' : 'Offline'}
                          </span>

                          <span onClick={() => onCopyClick(exam.id)} className={`copy-id ${index !== hoveredRow ? 'opacity-0' : ''}`}>
                            {copyIdText}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="caption">
                      <div>
                        <span className="text-questions">{exam.questions?.length} Questions</span>
                      </div>

                      <div className="time">
                        <FontAwesomeIcon className="clock-icon" icon={faClock} /> <span>{exam.timeMinutes} Mins</span>
                      </div>

                      {!props.isAdminPage && !props.isFreePage && (
                        <div className="retries-icon">
                          <img className="retries-icon-image" src="/icons/repeat-solid.svg" height={14} />{' '}
                          <span className="retries-text">{exam.retries} Retries</span>
                        </div>
                      )}
                    </div>
                  </td>

                  {!props.isAdminPage && (props.resultsLoaded === undefined || props.resultsLoaded) && (
                    <td>
                      {(!exam?.isPaid || props.unlocked || user.role == 1) && !Utils.isFaceToFace(exam) && (
                        <div className="start-element">
                          {exam?.isOnline && (
                            <button onClick={() => showConfirmDialog(exam)} className="btn btn-primary">
                              {exam.onGoing ? 'Resume' : ''}
                              {!exam.onGoing && exam.doneOnce ? 'Retake' : ''}
                              {!exam.onGoing && !exam.doneOnce ? (!exam.isPaid && !props.unlocked ? 'Try for Free' : 'Start') : ''}
                              <ChevronDoubleRight />
                            </button>
                          )}
                        </div>
                      )}

                      {exam?.isOnline && exam?.isPaid && !props.unlocked && user.role != 1 && (
                        <button onClick={() => showPaymentDialog(exam)} className="start-element">
                          <span className={'text-expected'}>
                            {!exam.onGoing && exam.isPaid && !props.unlocked ? 'Available Now. Click to Access' : ''}
                          </span>
                        </button>
                      )}

                      {!exam?.isOnline && (
                        <button onClick={() => showPaymentDialog(exam)} className="start-element">
                          <span className={'text-expected orange'}>
                            Locked until: {new Date(exam.expectedDate).toLocaleDateString()}
                          </span>
                        </button>
                      )}
                    </td>
                  )}

                  {props.resultsLoaded === false && (
                    <td>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  )}

                  {props.isAdminPage && (
                    <td>
                      <div className="start-element">
                        <button onClick={() => showConfirmDialog(exam)} className="btn btn-primary">
                          Preview
                          <ChevronDoubleRight />
                        </button>
                      </div>
                    </td>
                  )}

                  {props.isAdminPage && (
                    <td>
                      <span>{exam?.questions?.length || 0} Questions</span>
                    </td>
                  )}

                  {props.isAdminPage && (
                    <td>
                      <span>{exam?.timeMinutes || 0} mins</span>
                    </td>
                  )}

                  <td className="difficulty">
                    <div className="text-difficulty">
                      <StarFill className="first-star" />
                      <StarFill className={exam.difficulty >= 2 ? '' : 'faded-star'} />
                      <StarFill className={exam.difficulty < 3 ? 'faded-star' : ''} />
                    </div>
                  </td>

                  {!props.isAdminPage && !props.isFreePage && (
                    <td className="score">
                      <span>{exam.score}</span>
                    </td>
                  )}

                  <td>
                    <div className="action-icons">
                      {!exam.noResults && !props.isFreePage && !props.isAdminPage && (
                        <div onClick={() => showResultModal(exam)}>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(e) => renderTooltip(e, 'Statistics')}
                          >
                            <img style={{ height: iconSize }} src="/icons/statistics-icon.png" alt="statistics-icon" />
                          </OverlayTrigger>
                        </div>
                      )}
                      {!props.isAdminPage && (
                        <div onClick={() => showPaymentDialog(exam)} className={!exam?.isPaid || props.unlocked ? 'lock-div' : ''}>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(e) => renderTooltip(e, exam?.isPaid && !props.unlocked ? 'Locked' : 'Unlocked')}
                          >
                            {exam?.isPaid && !props.unlocked && user.role != 1 ? (
                              <img
                                style={{ height: iconSize }}
                                className="locked-icon"
                                src="/icons/locked.svg"
                                onClick={() => modalPaymentRef.current.handleShow()}
                                alt="locked-icon"
                              />
                            ) : (
                              <img style={{ height: iconSize }} src="/icons/open-icon.png" alt="open-icon" />
                            )}
                          </OverlayTrigger>
                        </div>
                      )}

                      {props.isAdminPage && (
                        <div>
                          <img style={{ height: iconSize }} src="/icons/edit.svg" onClick={() => goToCreateExam(exam)} />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <ConfirmModal
        ref={confirmModalRef}
        onConfirm={goToPerformExam}
        title="Exam Start"
        confirmBtn="Start"
        description={'Your exam is about to start, estimated time is ' + selectedExam?.timeMinutes + ' minutes, ready to start?'}
      />

      <Modal show={showZoomModal} onHide={handleCloseZoom} centered dialogClassName={'cover-modal'}>
        <Modal.Body>
          <div>
            <img style={{ borderRadius: '12px' }} src={selectedExam?.coverImageUrl} onLoad={() => setLoadingCover(false)} />
            {loadingCover && (
              <div className="loader-container">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            <div className="custom-success-footer">
              <Button
                style={{ height: '42px', marginRight: '15px' }}
                onClick={handleCloseZoom}
                variant="light"
                className="back-btn btn-short"
              >
                <span>Cancel</span>
              </Button>

              <Button style={{ width: '95px' }} id="button-start-modal" onClick={() => goToPerformExam()}>
                Start
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmModal
        ref={confirmOnlineRef}
        onConfirm={makeOnline}
        title="Exam status change confirmation"
        confirmBtn="Confirm"
        description={'Are you sure you want to make ' + selectedExam?.title + ' online?'}
      />
      <ConfirmModal
        ref={confirmOfflineRef}
        onConfirm={makeOffline}
        title="Exam status change confirmation"
        confirmBtn="Confirm"
        description={'Are you sure you want to make ' + selectedExam?.title + ' offline?'}
      />
      <ConfirmModal
        ref={goToExamEditRef}
        onConfirm={goToExamEdit}
        title="Exam errors"
        confirmBtn="Confirm"
        description={
          'The ' +
          selectedExam?.title +
          ' has missing information and cannot be set online, would you like to go to the exam edit page?'
        }
      />

      <ExamResultsModal ref={examResultModalRef} title={selectedExam?.title + ' results'} />
      <ModalPayment
        onConfirm={(linkUrl: string) => onSelectPlan(linkUrl)}
        description={
          props.isFreePage ? (
            <span>
              Select the desired plan below, you will be taken to create your account to then be redirected to the payment page!
            </span>
          ) : (
            <span>Subscribe to get access to all our premium exams now!</span>
          )
        }
        ref={modalPaymentRef}
        title="payment"
      ></ModalPayment>
    </div>
  );
};

export { TableWidget };
