import { collection, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import { ChevronDoubleRight, StarFill } from 'react-bootstrap-icons';
import { useUserState } from '../../services/User';
import { db, useAuthState } from '../../services/firebase';
import { QueryUtils } from '../../util/query-utils';
import { Exam } from '../../models/exam';
import { Answer } from '../../models/answer';
import { ExamResultsModal } from '../exam-results-modal/ExamResultsModal';
import { Question } from '../../models/question';
import { ExamResult } from '../../models/exam-result';
import { useStaticState } from '../../services/StaticContext';
import { Role } from '../../models/role.enum';

type Props = {
  //exams: Exam[] | undefined;
  isAdminPage?: boolean;
  resultsLoaded?: boolean;
  isFreePage?: boolean;
  unlocked?: boolean;
  onStatusChange?: any;
};

const TableWidgetFace: React.FC<Props> = (props: Props) => {
  const user: any = useUserState();
  const [exams, setExams] = useState<any[]>([]);
  const [selectedExam, setSelectedExam] = useState<any>();
  const { user: authUser, loading: isLoading } = useAuthState();
  const examResultModalRef: any = useRef(null);
  const { examTypes, subjects, subSubjects } = useStaticState();

  useEffect(() => {
    if (authUser?.uid) {
      //setLoading(true);
      console.log('authUser?.uid', authUser?.uid);
      getExamsFromDb();
    }
  }, [authUser?.uid]);

  async function getExamsFromDb() {
    const customQuery = query(
      collection(db, 'results-face-to-face'),
      where('firstName', '==', user.firstName),
      where('lastName', '==', user.lastName)
    );

    const adminQuery = query(collection(db, 'results-face-to-face'));

    const exams = await QueryUtils.executeQuery(user.role == Role.ADMIN ? adminQuery : customQuery);
    setExams(exams);
  }

  const renderTooltip = (props: any, tooltip: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  function getTotalQuestions(exam: any) {
    return Object.keys(exam).filter((key) => key.startsWith('q_')).length;
  }

  function convertDate(date: any) {
    return date.toDate().toDateString();
  }

  return (
    <div>
      <div id="exams-table" className="table-container">
        <div className="table-content">
          <Table>
            <thead>
              <tr>
                <th className="th-name">name</th>

                {props.isAdminPage && <th>questions</th>}

                <th>User</th>
                <th className="">score</th>
                <th className="">Upload Date</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {exams?.map((exam) => (
                <tr onClick={() => setSelectedExam(exam)} key={exam.id}>
                  <td className="exam-name-td">
                    <div className="name-first-row">{exam.module}</div>
                    <div className="caption">
                      <div>
                        <span className="text-questions">{getTotalQuestions(exam)} Questions</span>
                      </div>
                    </div>
                  </td>

                  {props.resultsLoaded === false && (
                    <td>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  )}

                  <td>{`${exam.forename} ${exam.surname}`}</td>

                  {!props.isAdminPage && !props.isFreePage && (
                    <td className="score">
                      <span>
                        {exam.rawscore || 0}/{getTotalQuestions(exam)}
                      </span>
                    </td>
                  )}

                  <td>
                    <div className="score">
                      <span>{convertDate(exam?.uploadDate)}</span>
                    </div>
                  </td>

                  <td className="score">
                    {exam.result}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <ExamResultsModal ref={examResultModalRef} title={selectedExam?.title + ' results'} />
    </div>
  );
};

export { TableWidgetFace };
